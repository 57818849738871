<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import arrowLeftIconURL from '../../assets/images/arrow-left.svg';

const { t } = useI18n({});

type Props = {
  logoutPath: string;
};

defineProps<Props>();
</script>
<template>
  <div class="flex flex-1 justify-center md:items-center">
    <div class="mx-auto w-full max-w-xl rounded-2xl bg-white pt-6 md:px-24 md:py-12">
      <a
        class="mb-2 block px-6 text-primary md:mb-8 md:px-0"
        :href="logoutPath"
      >
        <inline-svg
          :src="arrowLeftIconURL"
          class="mr-2 inline h-5"
        />{{ t('common.backToHome') }}
      </a>
      <div class="text-center">
        <h1 class="mb-4 text-3xl font-bold text-slate-900">
          {{ t('unauthorizedSession.title') }}
        </h1>
        <p class="text-sm text-slate-900 md:mb-8">
          {{ t('unauthorizedSession.subtitle') }}
        </p>
      </div>
    </div>
  </div>
</template>
