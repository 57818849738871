<script setup lang="ts">
import { computed } from 'vue';

import { useSiteVariables } from '@/composables/useSiteVariables';

import arrowLeftIconURL from '../../assets/images/arrow-left.svg';

const { logoUrl } = useSiteVariables();

type Props = {
  backHref?: string;
  emitBackEvent?: boolean
  title: string;
  subtitle: string;
};

const props = withDefaults(defineProps<Props>(), {
  backHref: undefined,
  emitBackEvent: false,
});

const emit = defineEmits<{
  (event: 'back'): void;
}>();

const showBack = computed(() => props.backHref || props.emitBackEvent);

function handleBackClick() {
  if (props.emitBackEvent) emit('back');
}

</script>

<template>
  <div class="flex flex-col gap-y-4 pb-4 text-center sm:gap-y-8 sm:pb-8">
    <base-button
      v-if="showBack"
      data-testid="back-button"
      :href="backHref"
      class="mb-4 self-start text-sm sm:mb-0"
      theme="primary-link"
      @click="handleBackClick"
    >
      <inline-svg
        :src="arrowLeftIconURL"
        class="mr-2 inline h-5"
      />
      {{ $t('common.back') }}
    </base-button>
    <img
      :src="logoUrl"
      class="mx-auto h-10 sm:h-16"
    >
    <h1 class="text-base font-semibold text-slate-900 sm:text-2xl">
      {{ title }}
    </h1>
    <p class="text-center text-xs text-slate-900 sm:text-sm">
      {{ subtitle }}
    </p>
  </div>
</template>
