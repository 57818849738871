<script setup lang="ts">
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { object, string, ref as yupRef } from 'yup';

import type { UserForm } from '@/api/users';
import type { UserSessionMode } from '@/types/user-session-mode';

import UserSessionHeader from './user-session-header.vue';

const { t } = useI18n();

const emit = defineEmits<{
  (event: 'toggle-mode', value?: UserSessionMode): void;
  (event: 'submit', values: UserForm): void;
}>();

const MIN_PASSWORD_LENGTH = 6;
const validationSchema = object().shape({
  password: string().required().min(MIN_PASSWORD_LENGTH),
  passwordConfirmation: string().required().oneOf([yupRef('password')], t('validation.custom.passwordConfirmation')),
});

const { values, handleSubmit } = useForm<UserForm>(
  {
    validationSchema,
    validateOnMount: true,
  },
);

function getResetPasswordToken(): string {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get('reset_password_token') || '';
}

const submitForm = handleSubmit(() => {
  values.resetPasswordToken = getResetPasswordToken();
  emit('submit', values);
});

</script>

<template>
  <user-session-header
    :title="t('userSession.updatePasswordPage.title')"
    :subtitle="t('userSession.updatePasswordPage.subtitle')"
  />
  <form class="flex flex-col gap-y-4">
    <base-input
      :label="t('userSession.password')"
      type="password"
      autocomplete="new-password"
      name="password"
    />
    <base-input
      :label="t('userSession.passwordConfirmation')"
      type="password"
      autocomplete="new-password"
      name="passwordConfirmation"
    />
    <base-button
      class="w-full self-center bg-primary"
      @click="submitForm"
    >
      {{ t('userSession.resetPassword') }}
    </base-button>
    <i18n-t
      keypath="userSession.noAccount"
      tag="div"
      class="text-center text-sm text-slate-900"
    >
      <template #register>
        <button
          type="button"
          class="font-medium text-primary"
          @click="emit('toggle-mode', 'register')"
        >
          {{ t('userSession.registerHere') }}
        </button>
      </template>
    </i18n-t>
  </form>
</template>
