<script setup lang="ts">
import { useAttrs } from 'vue';

import BaseInput from './base-input.vue';

interface Props {
  modelValue?: string;
  label: string;
  name: string;
  phonePrefix: string;
  flag?: string;
}

withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  flag: undefined,
});

const attrs = useAttrs();

function handleInputChange(e: Event) {
  const input = e.target as HTMLInputElement;
  input.value = phoneFormatFunction(input.value);
}

function phoneFormatFunction(phone: string) {
  return phone.replace(/[^0-9]/g, '');
}

</script>
<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<template>
  <base-input
    v-bind="attrs"
    type="tel"
    :label="label"
    :name="name"
    @input="handleInputChange"
  >
    <template #left>
      <span v-if="flag">
        {{ flag }}
      </span>
      <span>
        {{ phonePrefix }}
      </span>
    </template>
  </base-input>
</template>
