<script setup lang="ts">
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { object, string } from 'yup';

import type { UserForm } from '@/api/users';
import type { UserSessionMode } from '@/types/user-session-mode';

import UserSessionHeader from './user-session-header.vue';

const { t } = useI18n();

type Props = {
  refererUrl: string;
};

const emit = defineEmits<{
  (event: 'toggle-mode', value?: UserSessionMode): void;
  (event: 'submit', values: UserForm): void;
}>();

const validationSchema = object().shape({
  email: string().required().email(),
});

const { values, handleSubmit } = useForm<UserForm>(
  {
    validationSchema,
    validateOnMount: true,
  },
);

const submitForm = handleSubmit(() => {
  emit('submit', values);
});

defineProps<Props>();
</script>

<template>
  <user-session-header
    :back-href="refererUrl"
    :title="t('userSession.resetPasswordPage.title')"
    :subtitle="t('userSession.resetPasswordPage.subtitle')"
  />
  <form
    class="flex flex-col gap-y-4"
  >
    <base-input
      :label="t('userSession.user')"
      name="email"
      autocomplete="email"
    />
    <base-button
      class="w-full self-center bg-primary"
      @click="submitForm"
    >
      {{ t('userSession.resetPassword') }}
    </base-button>
    <i18n-t
      keypath="userSession.noAccount"
      tag="div"
      class="text-center text-sm text-slate-900"
    >
      <template #register>
        <button
          type="button"
          class="font-semibold text-primary"
          @click="emit('toggle-mode', 'register')"
        >
          {{ t('userSession.registerHere') }}
        </button>
      </template>
    </i18n-t>
  </form>
</template>
