<script setup lang="ts">
import { useForm } from 'vee-validate';
import { object, string } from 'yup';

import type { UserForm } from '@/api/users';
import type { UserSessionMode } from '@/types/user-session-mode';

import UserSessionHeader from './user-session-header.vue';

type Props = {
  refererUrl: string;
  passwordRecoveryEnabled: boolean;
};

const emit = defineEmits<{
  (event: 'toggle-mode', value?: UserSessionMode): void;
  (event: 'submit', values: UserForm): void;
}>();

const validationSchema = object().shape({
  email: string().required().email(),
  password: string().required(),
});

const { values, handleSubmit } = useForm<UserForm>(
  {
    validationSchema,
    validateOnMount: true,
  },
);

const submitForm = handleSubmit(() => {
  emit('submit', values);
});

defineProps<Props>();
</script>

<template>
  <user-session-header
    :back-href="refererUrl"
    :title="$t('userSession.loginPage.title')"
    :subtitle="$t('userSession.loginPage.subtitle')"
    @back="emit('toggle-mode', 'login')"
  />
  <form class="flex flex-col gap-y-4">
    <base-input
      :label="$t('userSession.user')"
      name="email"
      autocomplete="email"
    />
    <div class="flex flex-col gap-y-4">
      <base-input
        :label="$t('userSession.password')"
        type="password"
        name="password"
        autocomplete="current-password"
      />
      <button
        v-if="passwordRecoveryEnabled"
        type="button"
        class="self-end text-sm font-medium text-primary"
        @click="emit('toggle-mode', 'resetPassword')"
      >
        {{ $t('userSession.forgotPassword') }}
      </button>
    </div>
    <base-button
      class="w-full self-center bg-primary"
      @click="submitForm"
    >
      {{ $t('userSession.login') }}
    </base-button>
    <i18n-t
      keypath="userSession.noAccount"
      tag="div"
      class="text-center text-sm text-slate-900"
    >
      <template #register>
        <button
          type="button"
          class="font-medium text-primary"
          @click="emit('toggle-mode', 'register')"
        >
          {{ $t('userSession.registerHere') }}
        </button>
      </template>
    </i18n-t>
  </form>
</template>
