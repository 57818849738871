<script setup lang="ts">
import { useForm } from 'vee-validate';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { object, string, boolean, ref as yupRef } from 'yup';

import type { UserForm } from '@/api/users';
import type { CountryCode } from '@/types/country-code';
import type { UserSessionMode } from '@/types/user-session-mode';
import { dniUtils } from '@/utils/dni-utils';

import UserSessionHeader from './user-session-header.vue';

const { t } = useI18n();

type Props = {
  countryCode: CountryCode;
  countryNationalIdName: string;
  countryFlagIcon?: string;
  mobilePhoneCodePrefix: string;
  mobilePhoneNationalPattern: string;
  hideNationalId: boolean;
  webAppBaseUrl: string;
  isTermsAndConditionsActive: boolean;
  isPrivacyPolicyActive: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  countryFlagIcon: undefined,
});

const { format: formatDni, validate: validateDni } = dniUtils[props.countryCode] || {};

const showPoliciesSection = computed(() => props.isTermsAndConditionsActive || props.isPrivacyPolicyActive);

function dniValidateFunction(value: string) {
  return validateDni ? validateDni(value) : true;
}

function dniFormatFunction(value: string) {
  return formatDni ? formatDni(value) : value;
}

const emit = defineEmits<{
  (event: 'toggle-mode', value?: UserSessionMode): void;
  (event: 'submit', values: UserForm): void;
}>();

const MIN_PASSWORD_LENGTH = 6;
const validationSchema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  email: string().required().email(),
  password: string().required().min(MIN_PASSWORD_LENGTH),
  passwordConfirmation: string().required().oneOf([yupRef('password')], t('validation.custom.passwordConfirmation')),
  mobilePhoneNumber: string().required().matches(new RegExp(`^${props.mobilePhoneNationalPattern}$`)),
  nationalId: props.hideNationalId ? string() : string().required().test(dniValidateFunction),
  termsAndConditions: props.isTermsAndConditionsActive ? boolean().required() : boolean(),
  privacyPolicy: props.isPrivacyPolicyActive ? boolean().required() : boolean(),
});

const { values, handleSubmit } = useForm<UserForm>(
  {
    validationSchema,
    validateOnMount: true,
  },
);

const submitForm = handleSubmit(() => {
  emit('submit', values);
});

</script>

<template>
  <user-session-header
    :title="t('userSession.registerPage.title')"
    :subtitle="t('userSession.registerPage.subtitle')"
    emit-back-event
    @back="emit('toggle-mode', 'login')"
  />
  <form class="flex flex-col gap-y-6">
    <base-input
      :label="t('userSession.firstName')"
      name="firstName"
    />
    <base-input
      :label="t('userSession.lastName')"
      name="lastName"
    />
    <base-input
      v-if="!props.hideNationalId"
      :label="countryNationalIdName"
      name="nationalId"
      @input="($event.target as HTMLInputElement).value = dniFormatFunction($event.target.value)"
    />
    <base-phone-input
      :label="t('userSession.mobilePhoneNumber')"
      name="mobilePhoneNumber"
      :phone-prefix="mobilePhoneCodePrefix"
      :flag="countryFlagIcon"
    />
    <base-input
      :label="t('userSession.email')"
      autocomplete="email"
      name="email"
    />
    <p class="text-sm text-slate-900">
      {{ t('userSession.createPassword') }}
    </p>
    <base-input
      :label="t('userSession.password')"
      type="password"
      autocomplete="new-password"
      name="password"
    />
    <base-input
      :label="t('userSession.passwordConfirmation')"
      type="password"
      autocomplete="new-password"
      name="passwordConfirmation"
    />
    <div
      v-if="showPoliciesSection"
      class="flex flex-col gap-4"
    >
      <base-checkbox-input
        v-if="props.isTermsAndConditionsActive"
        :checked-value="true"
        name="termsAndConditions"
      >
        <p>
          {{ t('userSession.acceptTermsAndConditions') }}
          <a
            :href="`${props.webAppBaseUrl}/terms-and-conditions`"
            class="underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ t('userSession.termsAndConditions') }}
          </a>
        </p>
      </base-checkbox-input>

      <base-checkbox-input
        v-if="props.isPrivacyPolicyActive"
        :checked-value="true"
        name="privacyPolicy"
      >
        <p>
          {{ t('userSession.acceptPrivacyPolicy') }}
          <a
            :href="`${props.webAppBaseUrl}/privacy-policy`"
            class="underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ t('userSession.privacyPolicy') }}
          </a>
        </p>
      </base-checkbox-input>
    </div>
    <base-button
      class="w-full self-center"
      @click="submitForm"
    >
      {{ t('userSession.register') }}
    </base-button>
    <i18n-t
      keypath="userSession.haveAccount"
      tag="div"
      class="text-center text-sm text-slate-900"
    >
      <template #login>
        <button
          type="button"
          class="font-medium text-primary"
          @click="emit('toggle-mode', 'login')"
        >
          {{ t('userSession.loginHere') }}
        </button>
      </template>
    </i18n-t>
  </form>
</template>
