<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import phoneSvg from '../../assets/images/phone.svg';

interface Props {
  logoUrl?: string;
  contactPhone?: string;
}

withDefaults(
  defineProps<Props>(),
  {
    logoUrl: undefined,
    contactPhone: undefined,
  },
);

const { t } = useI18n();
</script>

<template>
  <div class="flex flex-col justify-between gap-y-4 bg-gray-900 px-6 py-8 md:flex-row md:px-8 md:py-16">
    <div class="flex max-w-max flex-col justify-center">
      <img
        class="h-8"
        :src="logoUrl"
      >
    </div>
    <div
      v-if="contactPhone"
      class="flex text-gray-300"
    >
      <inline-svg
        :src="phoneSvg"
        class="mr-6 h-8 w-8 stroke-gray-300"
      />
      <div>
        <p class="font-bold">
          {{ contactPhone }}
        </p>
        <p>
          {{ t('footer.phoneMessage') }}
        </p>
      </div>
    </div>
  </div>
</template>
