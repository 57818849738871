import { Field, Form, ErrorMessage } from 'vee-validate';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import InlineSvg from 'vue-inline-svg';
import * as yup from 'yup';

import BaseButton from '../components/base-button.vue';
import BaseCheckboxInput from '../components/base-checkbox-input.vue';
import BaseInput from '../components/base-input.vue';
import BasePhoneInput from '../components/base-phone-input.vue';
import TheAppList from '../components/the-app-list.vue';
import TheFooter from '../components/the-footer.vue';
import TheNavbar from '../components/the-navbar.vue';
import UnauthorizedSession from '../components/unauthorized-session.vue';
import UserSession from '../components/user-session.vue';
import '../css/application.css';
import { useSiteVariables } from '../composables/useSiteVariables';
import { locales } from '../locales/locales';

const { locale } = useSiteVariables();

const i18n = createI18n({
  legacy: false,
  locale,
  messages: locales.messages,
});

yup.setLocale(locales.messages[locale].validation.yup);

const components = [
  { name: 'InlineSvg', component: InlineSvg },
  { name: 'VField', component: Field },
  { name: 'VForm', component: Form },
  { name: 'VErrorMessage', component: ErrorMessage },
  { name: 'BaseInput', component: BaseInput },
  { name: 'BaseButton', component: BaseButton },
  { name: 'BaseCheckboxInput', component: BaseCheckboxInput },
  { name: 'BasePhoneInput', component: BasePhoneInput },
];

document.addEventListener('DOMContentLoaded', () => {
  const app = createApp({
    components: { UserSession, UnauthorizedSession, TheNavbar, TheFooter, TheAppList },
  });

  components.forEach(({ name, component }) => {
    app.component(name, component);
  });

  app.use(i18n);
  app.mount('#vue-app');

  return app;
});
