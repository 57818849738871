<script setup lang="ts">
import { computed, useAttrs } from 'vue';

interface Props {
  theme?: string;
  loading?: boolean;
}

const props = withDefaults(
  defineProps<Props>(),
  {
    theme: 'primary-button',
    loading: false,
  },
);

const themeStyles = {
  'primary-button': 'text-white bg-primary hover:bg-primary/70 px-6',
  'outline-button': 'text-black bg-transparent border-primary border-2 border hover:bg-primary hover:text-white px-6',
  'primary-link': 'text-primary',
};

const attrs = useAttrs();
const currentTheme = computed(() => themeStyles[props.theme as keyof typeof themeStyles]);
const currentTag = computed(() => (attrs.href ? 'a' : 'button'));
</script>

<template>
  <component
    :is="currentTag"
    class="flex h-min items-center justify-center rounded-md p-2.5 text-center text-sm"
    :class="currentTheme"
  >
    <div
      v-if="loading"
      class="flex h-max grow items-center justify-center"
    >
      <inline-svg
        :src="require('assets/images/loading.svg')"
        class="mr-2 h-6 animate-spin stroke-current"
      />
    </div>
    <slot />
  </component>
</template>
